import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  border-radius: var(--spacing-small);
  
  ${({ autoHeight }) => (!autoHeight) && `
    padding-bottom: 50%;
  `}
`;

const Style = styled.iframe`
  border: none;
  border-radius: var(--spacing-small);
  width: 100%;
  
  ${({ autoHeight, contentHeight }) => ((!autoHeight) ? `
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  ` : `
    height: ${contentHeight}px;
  `)}
`;

const Iframe = ({ type, autoHeight }) => {
  const [height, setHeight] = useState(685);
  const [iframeSrc, setIframeSrc] = useState('');
  const ref = useRef();

  // Needed to do it this way because Gatsby doesn't seem to like it when you use fancy dynamic
  // selectors for environment variables...
  const typeURLs = {
    glossary: process.env.GATSBY_AD_GLOSSARY_URL,
    mythbuster: process.env.GATSBY_AD_MYTH_BUSTERS_URL,
    adct: process.env.GATSBY_AD_ADCT_URL,
  };

  const url = typeURLs[type.toLowerCase()];

  const handleMessage = (e) => {
    // Do not process messages that aren't from the iFrame.
    if (e.origin === url) {
      if (e.data.type === 'scrollTop') {
        window.scrollTo({
          // (The offset from the window + The offset the user has scrolled) - Offset for the header
          top: (ref.current.getBoundingClientRect().top + window.scrollY) - 120,
          behavior: 'smooth',
        });
      } else {
        setHeight(e.data.height);
      }
    }
  };

  useEffect(() => {
    if (!iframeSrc) {
      setIframeSrc(url);
    }

    if (autoHeight) {
      window.addEventListener('message', handleMessage);
      return () => window.removeEventListener('message', handleMessage);
    }

    return false;
  }, []);

  return (
    <Wrapper autoHeight={autoHeight} ref={ref}>
      {iframeSrc && (
        <Style src={autoHeight ? iframeSrc : url} autoHeight={autoHeight} contentHeight={height} />
      )}
    </Wrapper>
  );
};

Iframe.propTypes = {
  type: PropTypes.string.isRequired,
  autoHeight: PropTypes.bool,
};

Iframe.defaultProps = {
  autoHeight: false,
};

export default Iframe;
